import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AlertsService } from '../transport/alerts/alerts.service';

@Injectable({ providedIn: 'root' })
export class FrogedService {
  unreadMessages$ = new BehaviorSubject(0);
  bubbleClosed$ = new BehaviorSubject(false);

  constructor(private http: HttpClient, private alertService: AlertsService) {}

  private Froged(method: string, param1?: any, param2?: any): any {
    try {
      return (<any>window[<any>'Froged'])(method, param1, param2);
    } catch (error) {
      return null;
    }
  }

  getNumChatUnreadMessages$(): Observable<any> {
    return this.unreadMessages$.asObservable();
  }

  set(atts: any): void {
    this.Froged('set', atts);
  }

  track(eventName: string, meta: any = {}): void {
    this.Froged('track', eventName, meta);
  }

  toggle(): void {
    this.Froged('toggle');
  }

  startConversation(message: any): void {
    if (!this.Froged('opened')) {
      this.toggle();
    }

    this.Froged('message', message);
  }

  find(message: any): void {
    this.Froged('find', message);
  }

  openConversation(): void {
    this.Froged('open', 'inbox');
  }

  onInboxStatusUpdate(): void {
    this.Froged(
      'hook',
      'onInboxStatusUpdate',
      (unreadMessagesCount: number) => {
        if (unreadMessagesCount > 0) {
          this.unreadMessages$.next(unreadMessagesCount);
        }
      }
    );
  }

  onInboxUpdate(): void {
    if (this.Froged('connected')) {
      this.callOnInboxUpdate();
    } else {
      this.Froged('hook', 'onConnect', () => {
        this.callOnInboxUpdate();
      });
    }

    this.Froged('hook', 'onClose', () => {
      this.bubbleClosed$.next(true);
    });
  }

  callOnInboxUpdate(): void {
    this.Froged('listen');
    this.Froged('hook', 'onInboxUpdate', (unreadConversations: any) => {
      const unreadConversationsWithFromURL = unreadConversations.filter(
        (conversation) => conversation?.fromUrl
      );
      this.unreadMessages$.next(unreadConversationsWithFromURL.length);

      const options = {
        page: {
          pageSize: 50,
          currentPage: 1,
        },
      };

      this.alertService.getChatAlerts(options).subscribe((res) => {
        let sendToAPI: ConversationData[] = [];

        // Conversaciones a añadir
        unreadConversations.forEach((unread) => {
          const id = this.getBookingIDFromURL(unread.fromUrl);
          const auxFilter = res.notifications.filter(
            (notification) => notification.booking === id
          );

          if (auxFilter.length === 0 && id) {
            sendToAPI.push({
              bookingReferenceFromURL: id,
              lastMessageDate: unread.lastMessageAt,
              isBookingID: id.includes('-'),
              hide: 0,
            });
          }
        });

        // Conversaciones a borrar
        res.notifications.forEach((notification) => {
          const auxFilter = unreadConversations.filter(
            (unread) =>
              this.getBookingIDFromURL(unread.fromUrl) === notification.booking
          );

          if (auxFilter.length === 0) {
            sendToAPI.push({
              bookingReferenceFromURL: notification.booking,
              lastMessageDate: notification.created_at,
              isBookingID: notification.booking.includes('-'),
              hide: 1,
            });
          }
        });
        this.sendNotificationsFroged(sendToAPI).subscribe();
      });
    });
  }

  // private localStorageConversationsControl(unreadConversations: any) {
  //   let notifications: ConversationData[] = [];

  //   const localData = JSON.parse(localStorage.getItem('froged')!);

  //   if (localData.length > unreadConversations.length) {
  //     const readedChat = localData.filter((storage) =>
  //       unreadConversations.filter(
  //         (conversation) => conversation.id !== storage.id
  //       )
  //     );

  //     if (readedChat[0]?.fromUrl) {
  //       const id = this.getBookingIDFromURL(readedChat[0].fromUrl);

  //       notifications.push({
  //         bookingReferenceFromURL: id,
  //         lastMessageDate: readedChat[0].lastMessageAt,
  //         isBookingID: id.includes('-'),
  //         hide: 1,
  //       });

  //       this.sendNotificationsFroged(notifications).subscribe();
  //     }
  //   } else if (localData.length < unreadConversations.length) {
  //     this.sendNotifications(unreadConversations);
  //   }
  // }

  private sendNotificationsFroged(notifications: ConversationData[]) {
    if (notifications.length === 0) {
      return EMPTY;
    }

    notifications.forEach((notification) => {
      notification.bookingReferenceFromURL =
        notification.bookingReferenceFromURL?.split('?')[0];
    });

    return this.http.post<any>(
      environment.api_backend_url + '/api/notifications',
      { notifications }
    );
  }

  // private sendNotifications(unreadConversations: any) {
  //   const notifications: ConversationData[] = [];
  //   //listado ids reservas que estan enviandose para la notificación
  //   // const localDataFrogedNotificationSending = JSON.parse(
  //   //   localStorage.getItem('frogedIdsNotifications')!
  //   // );
  //   let listIdNotificationsInProgressPeticion: string[] = [];
  //   unreadConversations.forEach((conversation) => {
  //     if (conversation?.fromUrl) {
  //       const id = this.getBookingIDFromURL(conversation.fromUrl);
  //       //para evitar hacer la peticion repetida en caso de que no existan cambios en el listado y no se deba notificar a API que actualice
  //       // if (!localDataFrogedNotificationSending?.includes(id)) {

  //       // }

  //       listIdNotificationsInProgressPeticion.push(id);
  //       notifications.push({
  //         bookingReferenceFromURL: id,
  //         lastMessageDate: conversation.lastMessageAt,
  //         isBookingID: id.includes('-'),
  //       });
  //     }
  //   });
  //   //seteamos los ids de los chats que vana  la notificacion para API en el locastorage
  //   // localStorage.setItem(
  //   //   'frogedIdsNotifications',
  //   //   JSON.stringify(listIdNotificationsInProgressPeticion)
  //   // );
  //   if (notifications?.length > 0) {
  //     this.sendNotificationsFroged(notifications).subscribe({
  //       next: () => {
  //         // this.createNotificationsStorageIds(
  //         //   notifications,
  //         //   listIdNotificationsInProgressPeticion
  //         // );
  //       },
  //     });
  //   }

  //   return;
  // }

  createNotificationsStorageIds(
    notifications,
    listIdNotificationsInProgressPeticion
  ) {
    //Nos quedamos con los ids de las notificaciones que ya han pasado y nos quedamos con las necesarias
    const listNotificationsSent = notifications.filter(
      (notification) =>
        !listIdNotificationsInProgressPeticion.includes(
          notification.bookingReferenceFromURL
        )
    );
    //Actualizamos el listado de notificaciones que ya se ha solicitado su actualizacion a API
    localStorage.setItem(
      'frogedIdsNotifications',
      JSON.stringify(listNotificationsSent)
    );
  }

  private getBookingIDFromURL(url: string): string {
    return url?.split('/')[url?.split('/')?.length - 1];
  }

  logout(): void {
    this.Froged('shutdown');
  }

  public loadScript({ id, url }) {
    return new Promise((resolve, reject) => {
      if (id && document.getElementById(id)) {
        resolve({ id: id, loaded: true, status: 'Already Loaded' });
      }
      let body = document.body;
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = '';
      script.src = url;
      script.id = id;
      script.onload = () => {
        resolve({ id: id, loaded: true, status: 'Loaded' });
      };
      script.onerror = (error: any) =>
        resolve({ id: id, loaded: false, status: 'Loaded' });
      body.appendChild(script);
    });
  }

  public openTicket(bookingId: string, message: string): void {
    this.Froged('ticket', bookingId, message);
  }

  public openThread(conversation_id: string): void {
    this.Froged('thread', conversation_id);
  }
}

interface ConversationData {
  bookingReferenceFromURL: string;
  lastMessageDate: string;
  isBookingID: boolean;
  hide?: number;
}
